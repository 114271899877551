import { useLayoutEffect, useRef } from 'react';
import logoAllWhite from '../../../assets/images/logo-all-white.png';
import PriceLabel, { PriceType } from '../PriceLabel';
import createShadowRoot from '../ShadowRoot';
import { Offer4inA4 } from '../types';
import { styleContent } from './styles/styleContent';
import { getImage } from '../../functions/getImage';
import ResizableTextWrapper from '@components/resizeable-text/ResizableTextWrapper';
import { previewUpdateDelay } from '../../functions/print';

const Style = () => <style>{styleContent}</style>;

interface Props {
	offers: Offer4inA4[];
	iFrameId: string;
	show24FoodLogo: boolean;
}

const Preview = ({ show24FoodLogo, offers, iFrameId }: Props) => {
	const previewRef = useRef<HTMLDivElement | null>(null);

	useLayoutEffect(() => {
		const iframe = document.getElementById(iFrameId) as HTMLIFrameElement | null;
		if (iframe) {
			const iframeDocument = iframe.contentDocument;
			if (iframeDocument) {
				const iframeBody = iframeDocument.body;
				if (iframeBody) {
					setTimeout(() => {
						iframeBody.innerHTML = previewRef.current?.outerHTML || '';
					}, previewUpdateDelay);
					setTimeout(() => {
						iframeBody.innerHTML = previewRef.current?.outerHTML || '';
					}, previewUpdateDelay);
				}
			}
		}
	});

	return (
		<div ref={previewRef} className="preview">
			<Style />
			<div className="pt-four-print">
				{offers.map((offer) => (
					<div key={offer.id} className="pt-four-offer">
						<div className="pt-four-banner" />
						<div className="pt-four-banner-container">
							{show24FoodLogo && <img className="pt-four-logo" src={logoAllWhite} />}
							<ResizableTextWrapper
								textComponent={<h1 className="pt-four-title">{offer.title}</h1>}
								containerSize="100%"
								initialFontSize="70px"
							/>
						</div>
						<div className="pt-four-content">
							<h4 className="pt-four-product">{offer.product}</h4>
							<pre className="pt-four-extra">{offer.extra}</pre>
						</div>
						<div className="pt-four-image-container">
							<img
								className="pt-four-image"
								src={getImage(offer.image)}
								style={{ maxHeight: 240 * offer.imageScale }}
							/>
						</div>
						<div className="pt-four-price-container">
							{offer.type === PriceType.Price && (
								<PriceLabel
									type="price"
									price={offer.price}
									showPriceDecimals={offer.priceShowDecimals}
									unit={offer.unit}
								/>
							)}
							{offer.type === PriceType.PieceForPiece && (
								<PriceLabel
									type="price-for-piece"
									first={offer.first}
									second={offer.second}
									singlePrice={offer.singlePrice}
								/>
							)}
							{offer.type === PriceType.AmountForPrice && (
								<PriceLabel
									type="amount-for-price"
									quantity={offer.quantity}
									price={offer.price}
									showPriceDecimals={offer.priceShowDecimals}
									save={offer.save}
									showSaveDecimals={offer.saveShowDecimals}
								/>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default createShadowRoot(Preview);
